import { TableOutlined, UserOutlined } from "@ant-design/icons";
import {
  DeploymentIcon,
  LandingPageSettingIcon,
  NetworkIcon,
} from "src/assets/icons";

export const MENUS_KEY = {
  ADMIN_MANAGEMENT: "admin-management",
  DEPLOYMENT: "deployment-list",
  USER_MANAGEMENT: "user-management",
  NETWORKS: "networks",
  LANDING_PAGE: "landing-page-setting",
};

export const MENUS_LABEL = {
  USER_MANAGEMENT: "User management",
  CATEGORY_AND_ATTRIBUTE: "Category & Attribute",
};

export const SUB_KEY = {
  TRANSACTION: [{ key: "merchant", label: "Merchant", path: "merchant" }],
};

export const MENUS_ITEM = {
  USER_MANAGEMENT: {
    key: MENUS_KEY.USER_MANAGEMENT,
    label: "User Management",
    level: 1,
    icon: <UserOutlined />,
  },
  ADMIN_MANAGEMENT: {
    key: MENUS_KEY.ADMIN_MANAGEMENT,
    label: "Admin Management",
    level: 1,
    icon: <TableOutlined />,
  },
  NETWORKS: {
    key: MENUS_KEY.NETWORKS,
    label: "Network",
    level: 1,
    icon: <NetworkIcon />,
  },
  DEPLOYMENT: {
    key: MENUS_KEY.DEPLOYMENT,
    label: "Deployment List",
    level: 1,
    icon: <DeploymentIcon />,
  },
  LANDING_PAGE: {
    key: MENUS_KEY.LANDING_PAGE,
    label: "Landing page setting",
    level: 1,
    icon: <LandingPageSettingIcon />,
  },
};
