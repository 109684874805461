import { ServiceBase } from "./core/service-base";
export class AuthServices extends ServiceBase {
  // Implement method call API
  login = async <T, R>(params: {
    signature: string;
    address: string;
    provider: number;
  }): Promise<R> => {
    return this.post<T, R>("/admin/auth/connect-wallet", params);
  };

  getMessage = () => {
    return this.get(`/admin/auth/connect-wallet/public-key`);
  };

  verifyCode = async (params: { id: string; otp: string }) => {
    return this.post("auth/login/admin/verify", params);
  };

  refreshToken = (refresh_token: string) => {
    return this.post(`auth/refresh-token/admin`, { refresh_token });
  };

  forgotPassword = (email: string) => {
    return this.get(`auth/forgot-password/admin/${email}`);
  };

  resetPassword = (body: {
    new_password: string;
    reset_password_token: string;
  }) => {
    return this.post(`/auth/reset-password/admin`, body);
  };
}
