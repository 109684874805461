import { Button, ButtonProps } from "antd";
import React from "react";
import "./styles.scss";

interface Props extends ButtonProps {
  spinClassName?: string;
}

const GradientButton: React.FC<Props> = ({ className, children, loading, ...props }) => {
  return (
    <Button className={`gradient-button ${className || ""}`} loading={loading} {...props}>
      {children}
    </Button>
  );
};

export default GradientButton;
