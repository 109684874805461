import { LogoMenu } from "src/assets/icons";
import { Modal, ModalProps } from "antd";

import "./styles.scss";

const CustomModal = ({ children, className, title, ...props }: ModalProps) => {
  return (
    <Modal
      closable={false}
      width={845}
      footer={null}
      centered
      className={`modal-with-logo ${className ?? ""}`}
      {...props}
    >
      <div className="modal-header">
        <img src={LogoMenu} width={210} alt="logo" />
      </div>
      <div className="modal-content">
        {!!title && <div className="modal-title">{title}</div>}
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
