
export const PATHS = {
  default: () => "/",
  login: () => "/login",
  adminManagement: () => "/admin-management",
  deployment: () => "/deployment-list",
  userManagement: () => "/user-management",
  networks: () => "/networks",
  landingPageSetting: () => "/landing-page-setting",

};
