import { Dropdown } from "antd";
import React, { useState } from "react";
import Content, { ProfileDetailContentProps } from "./Content";

interface ProfileDetailProps extends ProfileDetailContentProps {
  children: React.ReactNode;
  isDisable?: boolean;
}

const ProfileDetail: React.FC<ProfileDetailProps> = ({
  children,
  isDisable = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        !isDisable ? (
          <Content {...props} isOpenDropdownWrapper={isOpen} />
        ) : (
          <></>
        )
      }
      placement="bottom"
      onOpenChange={(boolean: boolean) => {
        setIsOpen(boolean);
      }}
      overlayClassName="dropdown-wrapper"
    >
      {children}
    </Dropdown>
  );
};

export default ProfileDetail;
