import React from "react";
import "./styles.scss";
import CustomModal from "../..";
import GradientButton from "src/components/08.buttons/GradientButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DisconnectWalletModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <CustomModal
      title="Disconnect Wallet"
      open={isOpen}
      onCancel={onClose}
      wrapClassName={`disconnect-wallet-modal`}
    >
      <div className="modal-disconnect">
        <div className="modal-disconnect__content">
          <span>You have to connect your wallet again to trade.</span>{" "}
          <span>Are you sure?</span>
        </div>
        <GradientButton className="button-confirm" onClick={onConfirm}>
          Confirm
        </GradientButton>
      </div>
    </CustomModal>
  );
};

export default DisconnectWalletModal;
