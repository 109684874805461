import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AuthServices } from "src/services/auth-service";
import { setCurrentAccount } from "src/store/actions/account";
import { setIsWrongNetWork } from "src/store/actions/network";
import {
  setAccessTokenRedux,
  setStoreInfoUserIAO,
} from "src/store/actions/user";
import { WEB3_ERROR } from "src/types";
import { ConnectorKey, connectors } from "src/web3/connectors";
import { signMessage } from "src/web3/helpers";
import { useConnectWallet } from "src/web3/hooks";
import { setRoleAffiliateIAO } from "./../store/actions/user";
import {
  setStorageJwtToken,
  setStorageRefreshToken,
} from "src/helpers/storage";

export const IAOEndPoint = process.env.REACT_APP_API_ENDPOINT_IAO;

export const useLogin = () => {
  const dispatch = useDispatch();
  const { connectWallet, disconnectWallet } = useConnectWallet();
  const authService = new AuthServices();

  const getAccountConnected = async (
    connector: InjectedConnector | WalletConnectConnector,
    accountProps?: string
  ) => {
    if (accountProps) {
      return accountProps;
    }
    const provider = await connector.getProvider();
    const signer = new Web3Provider(provider).getSigner();
    const account = await signer.getAddress();
    return account;
  };

  const getSignature = async (
    accountSelected: string,
    connector: InjectedConnector | WalletConnectConnector
  ) => {
    const res = await authService.getMessage();
    const message = res?.data?.publicKey;
    const provider = await connector.getProvider();
    const signer = new Web3Provider(provider).getSigner();
    const signature = await signMessage(signer, message);
    return {
      message,
      signature,
    };
  };

  const resetStore = (accessToken: string, accountSelected: string) => {
    dispatch(setAccessTokenRedux(accessToken));
    dispatch(setCurrentAccount(accountSelected as string));
    dispatch(setIsWrongNetWork(false));
  };

  const getUserInfoIAO = async (accessToken: string) => {
    const res = await axios.get(`${IAOEndPoint}/api/account`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });
    dispatch(setStoreInfoUserIAO(res?.data?.data?.data));
    dispatch(setRoleAffiliateIAO(res?.data?.data?.data?.role));
  };

  const userLogin = async (connectorKey: ConnectorKey, account?: string) => {
    try {
      const connector = connectors[connectorKey];
      await connectWallet(connectorKey);
      const accountSelected = await getAccountConnected(connector, account);
      const { signature, message } = await getSignature(
        accountSelected,
        connector
      );
      console.log({
        signature,
        message,
      });
      const res = (await authService.login({
        signature,
        address: accountSelected,
        provider: 1,
      })) as any;
      const accessToken = res?.data?.access_token;
      const refreshToken = res?.data?.refresh_token;
      if (accessToken && refreshToken) {
        setStorageJwtToken(accessToken);
        setStorageRefreshToken(refreshToken);
      }
    } catch (error: any) {
      console.log("logout", error);
      resetStore("", "");
      disconnectWallet();

      let baseError = {
        type: "user_reject",
        message: error?.message,
        description: error,
      } as WEB3_ERROR;
      throw baseError;
    }
  };

  return { userLogin, getUserInfoIAO };
};
