import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Layout, Menu, MenuProps } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { ConnectWalletUrl, Logo } from "src/assets/icons";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import { MENUS_ITEM, MENUS_KEY } from "../../constants/sidebar";
import { useWalletListener } from "src/web3/hooks";

import "./styles.scss";
import ProfileDetail from "./ProfileDetail";
import GradientButton from "../08.buttons/GradientButton";
import { ellipseAddress } from "src/helpers";
import { useWeb3React } from "@web3-react/core";

const { Content, Sider } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const { account } = useWeb3React();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  useWalletListener();

  const goToThePage = (page: string) => {
    if (page.includes("/")) {
      return;
    }
    history.push(`/${page}`);
  };

  const SIDEBAR_MENUS: MenuProps["items"] = [
    {
      icon: MENUS_ITEM.USER_MANAGEMENT.icon,
      label: MENUS_ITEM.USER_MANAGEMENT.label,
      key: MENUS_ITEM.USER_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.USER_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.ADMIN_MANAGEMENT.icon,
      label: MENUS_ITEM.ADMIN_MANAGEMENT.label,
      key: MENUS_ITEM.ADMIN_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.ADMIN_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.NETWORKS.icon,
      label: MENUS_ITEM.NETWORKS.label,
      key: MENUS_ITEM.NETWORKS.key,
      onClick: () => goToThePage(MENUS_KEY.NETWORKS),
    },
    {
      icon: MENUS_ITEM.DEPLOYMENT.icon,
      label: MENUS_ITEM.DEPLOYMENT.label,
      key: MENUS_ITEM.DEPLOYMENT.key,
      onClick: () => goToThePage(MENUS_KEY.DEPLOYMENT),
    },
    {
      icon: MENUS_ITEM.LANDING_PAGE.icon,
      label: MENUS_ITEM.LANDING_PAGE.label,
      key: MENUS_ITEM.LANDING_PAGE.key,
      onClick: () => goToThePage(MENUS_KEY.LANDING_PAGE),
    },
  ];

  return (
    <>
      {hasStorageJwtToken() ? (
        <div id="layout">
          <Layout>
            <Sider width={250} theme="dark">
              <div
                className="logo"
                onClick={() => history.push(PATHS.default())}
              >
                <img src={Logo} alt="login-background" />
              </div>
              <Menu
                className="sidebar-menu"
                mode="inline"
                theme="dark"
                defaultSelectedKeys={[MENUS_KEY.USER_MANAGEMENT]}
                defaultOpenKeys={[]}
                items={SIDEBAR_MENUS}
                selectedKeys={[path]}
                expandIcon={(props) => {
                  const { isOpen } = props;
                  if (isOpen) {
                    return <MinusOutlined />;
                  } else {
                    return <PlusOutlined />;
                  }
                }}
              />
            </Sider>
            <Layout>
              <Layout.Header id="header">
                <ProfileDetail>
                  <GradientButton className="user-wallet-button">
                    <img src={ConnectWalletUrl} alt="connect-wallet" />
                    <span>{ellipseAddress(account || "", 6, 5)}</span>
                  </GradientButton>
                </ProfileDetail>
              </Layout.Header>
              <Content style={{ padding: "0 24px 24px" }}>{children}</Content>
            </Layout>
          </Layout>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LayoutDefault;
