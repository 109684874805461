import "antd/dist/antd.css";
import BigNumber from "bignumber.js";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "src/assets/scss/variable.scss";
import "src/assets/scss/_themes.scss";
import "./App.scss";
import Layout from "./components/01.layout";
import LocaleProviderComponent from "./components/15.locale-provider";
import ToastContext from "./contexts/toast";
import Routes from "./routes/Routes";
import initStore from "./store";
import Web3ActiveReactProvider from "./web3/components/Web3ActiveReactProvider";

BigNumber.config({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
});

const App: React.FC = () => {
  const { store } = initStore();
  return (
    <Web3ActiveReactProvider>
    <Provider store={store}>
      <LocaleProviderComponent>
        <BrowserRouter basename="">
          <ToastContext />
          <Layout>
            <Routes />
          </Layout>
        </BrowserRouter>
      </LocaleProviderComponent>
    </Provider>
    </Web3ActiveReactProvider>
  );
};

export default App;
