import loadable, { DefaultComponent } from "@loadable/component";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import { ExchangePageLoading, MarketPageLoading } from "src/pages/loadings";
import { PrivateRoute } from "./components/PrivateRoute";

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.default())) {
    return <ExchangePageLoading />;
  }
  return <MarketPageLoading />;
};

function loadableWFallback(
  loadFn: (props: {}) => Promise<DefaultComponent<{}>>
) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import("./components/NotFound"));

const LoginPage = loadableWFallback(() => import("src/pages/login"));

const LandingPageSettingPage = loadableWFallback(
  () => import("src/pages/landing-page")
);

const NetworksPage = loadableWFallback(() => import("src/pages/networks"));

const UserManagementPage = loadableWFallback(
  () => import("src/pages/user-management")
);

const DeploymentPage = loadableWFallback(() => import("src/pages/deployment"));

const AdminManagementPage = loadableWFallback(
  () => import("src/pages/admin-management")
);

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={PATHS.userManagement()} />}
      />
      <Route
        exact
        path={PATHS.login()}
        render={() =>
          hasStorageJwtToken() ? (
            <Redirect to={PATHS.default()} />
          ) : (
            <LoginPage />
          )
        }
      />
      <PrivateRoute
        exact
        path={PATHS.adminManagement()}
        component={AdminManagementPage}
      />
      <PrivateRoute
        exact
        path={PATHS.deployment()}
        component={DeploymentPage}
      />
      <PrivateRoute
        exact
        path={PATHS.userManagement()}
        component={UserManagementPage}
      />
      <PrivateRoute exact path={PATHS.networks()} component={NetworksPage} />
      <PrivateRoute
        exact
        path={PATHS.landingPageSetting()}
        component={LandingPageSettingPage}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
