/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useConnectWallet } from "src/web3/hooks";

import "./styles.scss";
import { useHistory } from "react-router";
import { PATHS } from "src/constants/paths";
import DisconnectWalletModal from "src/components/05.modal/components/DisconnectWalletModal";

export interface ProfileDetailContentProps {
  isOpenDropdownWrapper?: boolean;
}

const Content: React.FC<ProfileDetailContentProps> = ({
  isOpenDropdownWrapper,
}) => {
  const { disconnectWallet } = useConnectWallet();
  const history = useHistory();

  const [isOpenDisconnect, setIsOpenDisconnect] = useState<boolean>(false);

  const handleDisconnect = () => {
    disconnectWallet();
    history.push(PATHS.default());
  };

  const renderContentWithAuth = () => {
    return (
      <div className="profile-detail">
        <button
          className="logout-button"
          onClick={() => setIsOpenDisconnect(true)}
        >
          Disconnect
        </button>
      </div>
    );
  };

  return (
    <>
      {isOpenDropdownWrapper && renderContentWithAuth()}
      <DisconnectWalletModal
        isOpen={isOpenDisconnect}
        onClose={() => setIsOpenDisconnect(false)}
        onConfirm={handleDisconnect}
      />
    </>
  );
};

export default Content;
